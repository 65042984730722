*{
  padding: 0;
  margin:0;
  box-sizing: border-box;
  font-family: "Poppins", sans-serif;
}
body{
  background-color: #f43543;

}
.wrapper{
  width:400px;
  border: 1px solid white;
  position: absolute;
  transform: translate(-50%, -50%);
  top: 50%;
  left: 50%;
}
.container{
  width: 100%;
  background-color:#f43543;
  padding: 50px 40px;
  box-shadow: 0 20px 65px rgba(87,11,16,0.5);
  position: relative;
  border-radius: 5px;
  text-align: center;
}
.container:after{
  content: "";
  position: absolute;
  width: 80%;
  height: 120%;
  background-color: #ffffff;
  z-index: -1;
  top: -10%;
  left:10%;
}
.container p{
  color: #fdd8d8;
  line-height: 2;
  font-size: 18px;
}
.container h6{
  color: #ffffff;
  margin: 20px 0 60px 0;
  font-weight: 600;
  text-transform: capitalize;
}